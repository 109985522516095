import React from 'react'
import PropTypes from 'prop-types'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import Layout from '../components/Layout'
import SEO from '../components/SEO'

const NotFoundPage = ({ location }) => {
  return (
    <Layout headerColor="grey">
      <SEO title="404" description="404" location={location} />
      <div className="px-ogs bg-smoke pt-hh">
        <div className="-ml-gs flex">
          <div className="md:w-16/24 w-11/12 pl-gs">
            <div className="-ml-gs">
              <div className="w-full md:w-13/16 pl-gs py-2x-ogs">
                <div className="text-3xl lg:text-6xl leading-tight rte">
                  <h1 className="uppercase mb-2">404</h1>
                  Sorry, it seems you've found a page that doesn't exist. Please
                  click your back button or{' '}
                  <AniLink
                    className="hover:opacity-50 focus:opacity-50 transition"
                    to="/"
                    fade
                  >
                    click here
                  </AniLink>{' '}
                  to go back home.
                </div>
              </div>
            </div>
          </div>
          <div className="w-8/24 pl-gs">
            <div className="flush-right h-full bg-rose"></div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

NotFoundPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default NotFoundPage
